import store from "@/state/store.js";
import editIcon from "./editIcon.vue";
export default {
  data() {
    return {
      dialogVisible: false,
      confirmModal: false,
      editDialogVisible: false,
      page: 1,
      pageSize: 20,
      total: 0,
      buttonLoading: false,
      tableLoading: false,
      searchKey: "",
      formData: {
        name: "",
        description: "",
        currency: "",
        charge: ""
      },
      editFormData: {
        name: "",
        description: "",
        currency: "",
        charge: "",
        status: ""
      },
      rules: {
        name: [{
          required: true,
          message: "Account name is required.",
          trigger: "blur"
        }],
        description: [{
          required: true,
          message: "Description is required",
          trigger: "blur"
        }],
        currency: [{
          required: true,
          message: "Select a currency",
          trigger: "blur"
        }],
        charge: [{
          required: true,
          message: "Charge is required",
          trigger: "blur"
        }]
      }
    };
  },
  components: {
    editIcon
  },
  mounted() {
    this.tableLoading = true;
    store.dispatch("admin/reporting/getChartAccounts").then(data => {
      this.tableLoading = false;
    });
  },
  methods: {
    checkEnter(e) {
      e.preventDefault();
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmModal = true;
        } else {
          return false;
        }
      });
    },
    submitNewData() {
      this.buttonLoading = true;
      store.dispatch("admin/reporting/addChartAccount", this.formData).then(data => {
        this.buttonLoading = false;
        this.confirmModal = false;
        this.dialogVisible = false;
        this.$message({
          type: "success",
          message: "Account added successfully!"
        });
        this.$refs.ruleForm.resetFields();
        this.refetchData();
      }).catch(err => {
        this.buttonLoading = false;
        this.confirmModal = false;
        this.dialogVisible = false;
        this.$message({
          type: "error",
          message: "Error adding account!"
        });
        this.$refs.ruleForm.resetFields();
      });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    refetchData() {
      this.tableLoading = true;
      store.dispatch("admin/reporting/getChartAccounts").then(data => {
        this.tableLoading = false;
      });
    },
    openEditDialog(row) {
      this.editFormData = {
        account_id: row.row.account_id,
        name: row.row.name,
        description: row.row.description,
        currency: row.row.currency,
        status: row.row.status,
        charge: row.row.charge
      };
      this.editDialogVisible = true;
    },
    updateDetails(e) {
      e.preventDefault();
      // validate form
      if (this.editFormData.name == "" || this.editFormData.description == "" || this.editFormData.currency == "" || this.editFormData.charge == "" || this.editFormData.status == "") {
        this.$message({
          type: "error",
          message: "Please fill all fields!"
        });
        return;
      } else {
        this.buttonLoading = true;
        const payload = {
          account_id: this.editFormData.account_id,
          name: this.editFormData.name,
          description: this.editFormData.description,
          currency: this.editFormData.currency,
          status: this.editFormData.status,
          charge: this.editFormData.charge
        };
        store.dispatch("admin/reporting/updateChartAccount", payload).then(data => {
          this.buttonLoading = false;
          this.editDialogVisible = false;
          this.$message({
            type: "success",
            message: "Account updated successfully!"
          });
          this.refetchData();
        }).catch(err => {
          this.buttonLoading = false;
          this.editDialogVisible = false;
          this.$message({
            type: "error",
            message: "Error updating account!"
          });
        });
      }
    }
  },
  computed: {
    chartAccounts() {
      return store.getters["admin/reporting/chartAccounts"].filter(item => {
        return item.name.toLowerCase().includes(this.searchKey.toLowerCase()) || item.description.toLowerCase().includes(this.searchKey.toLowerCase()) || item.currency.toLowerCase().includes(this.searchKey.toLowerCase()) || item.currency.toLowerCase().includes(this.searchKey.toLowerCase());
      });
    }
  }
};